.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Poppins";
}
.login p {
  margin-right: 20px;
}

.header {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-around;
  z-index: 99;
  border-bottom: 4px solid transparent;
  -o-border-image: linear-gradient(45deg, #3494e6, #ec6ead);
  border-image: linear-gradient(45deg, #3494e6, #ec6ead);
  border-image-slice: 1;
  background-color: black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.wagmi-title-login {
  width: 200px;
  /* -webkit-animation: 1s in3; */
  /* animation: 1s in3; */
  font-size: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 80px;
}

.header-title-login {
  color: white;
  font-size: 27px;
  font-weight: 700;
  font-family: "Poppins";
  letter-spacing: 3px;
  /* -webkit-animation: 1s in; */
  /* animation: 1s in; */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.connect-button {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Poppins";
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  /* -webkit-animation: 1s in4; */
  /* animation: 1s in4; */
  color: white;
  background-color: black;
  border: 3px solid white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 80px;
}
.connect-button:hover {
  transform: scale(1.1);
}
.check-text-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: calc(50vh + 42px);
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 55vw;
}

.check-text {
  padding: 2vw;
  font-size: 40px;
  font-family: "Poppins";
  display: flex;
  align-items: center;
}


.login-general-links{
  position: fixed;
  bottom: 5%;
  left: 50%;
  width: 20%;
  transform: translate(-50%, 0);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-general-links-line{
  height: 4px;
  width: 70px;
  border-radius: 10px;
  background-color: white;
}

.login-general-links a{
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-general-links-img{
  width: 40px;
}


.alpha-text-container a {
  color: lightblue;
  cursor: pointer;
}
.instructions-img {
  height: 18px;
}

.instructions-btn {
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 20px;
  font-family: "Poppins";
  cursor: pointer;
  display: flex;
  align-items: center;
  color: black;
}
.instructions-btn div {
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.legal-btn {
  position: absolute;
  bottom: 20px;
  font-size: 20px;
  font-family: "Poppins";
  cursor: pointer;
  display: flex;
  align-items: center;
  color: black;
}

.legal-btn div {
  margin-left: 10px;
}

.legal-img {
  height: 18px;
  margin-left: 30px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 30px;
  filter: invert(1);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (max-width: 1600px){
  .login-general-links{
    bottom: 5%;
    width: 25%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 70px;
  }

  .login-general-links-img{
    width: 35px;
  }

}


@media screen and (max-width: 1400px){
  .login-general-links{
    bottom: 5%;
    width: 30%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 60px;
  }

  .login-general-links-img{
    width: 35px;
  }

}


@media screen and (max-width: 1100px){
  .login-general-links{
    bottom: 5%;
    width: 35%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 65px;
  }

  .login-general-links-img{
    width: 35px;
  }

}


@media screen and (max-width: 1000px) {
  .wagmi-title-login {
    width: 180px;
  }
  .header-title-login {
    font-size: 20px;
  }
  .connect-button {
    font-size: 14px;
  }
  .check-text {
    font-size: 36px;
  }
  .alpha-text-container {
    width: 50%;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 918px) {
  .alpha-text-container {
    width: 60%;
  }
}

@media screen and (max-width: 900px){
  .login-general-links{
    bottom: 5%;
    width: 30%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 45px;
  }

  .login-general-links-img{
    width: 30px;
  }

}

@media screen and (max-width: 870px) {
  .wagmi-title-login {
    width: 180px;
    margin-left: 20px;
  }
  .header-title-login {
    font-size: 20px;
  }
  .connect-button {
    font-size: 14px;
    margin-right: 20px;
  }
  .check-text {
    font-size: 36px;
  }
  .alpha-text-container {
    width: 60%;
  }
}

@media screen and (max-width: 820px) {
  .wagmi-title-login {
    width: 180px;
  }
  .header-title-login {
    font-size: 20px;
  }
  .connect-button {
    font-size: 14px;
    padding: 5px;
  }
  .check-text {
    font-size: 36px;
  }

    .login-general-links{
      bottom: 15%;
      width: 32%;
    }
    
    .login-general-links-line{
      height: 4px;
      width: 40px;
    }
  
    .login-general-links-img{
      width: 28px;
    }
  
}

@media screen and (max-width: 805px) {
  .wagmi-title-login {
    width: 180px;
  }
  .header-title-login {
    font-size: 20px;
  }
  .connect-button {
    font-size: 14px;
    padding: 6px;
    margin-left: 60px;
  }
  .check-text {
    font-size: 36px;
  }
}

@media screen and (max-width: 790px) {
  .wagmi-title-login {
    width: 160px;
  }
  .header-title-login {
    font-size: 18px;
  }
  .connect-button {
    font-size: 13px;
    padding: 5px;
    margin-left: 30px;
  }
  .check-text {
    font-size: 32px;
  }
  .alpha-text-container {
    font-size: 18px;
  }
}

@media screen and (max-width: 711px) {
  .wagmi-title-login {
    width: 160px;
  }
  .header-title-login {
    font-size: 18px;
  }
  .connect-button {
    font-size: 13px;
    padding: 5px;
    margin-left: 20px;
  }
  .check-text {
    font-size: 32px;
  }
  .alpha-text-container {
    font-size: 14px;
  }

  .login-general-links{
    bottom: 15%;
    width: 40%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 40px;
  }

  .login-general-links-img{
    width: 28px;
  }

}

@media screen and (max-width: 650px) {
  .wagmi-title-login {
    display: none;
  }
  .header-title-login {
    font-size: 18px;
  }
  .connect-button {
    font-size: 14px;
    padding: 8px;
  }
  .check-text-container {
    top: calc(50vh + 42px);
    width: 80vw;
  }
  .alpha-text-container {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 535px) {
  .alpha-text-container {
    width: 80%;
  }


  .login-general-links{
    bottom: 20%;
    width: 50%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 40px;
  }

  .login-general-links-img{
    width: 28px;
  }
}

@media screen and (max-width: 500px) {
  .header-title-login {
    font-size: 16px;
  }
  .connect-button {
    font-size: 13px;
    padding: 7px;
  }
  .check-text {
    width: 75vw;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 450px) {
  .header-title-login {
    display: none;
  }
  .wagmi-title-login {
    display: inline;
  }

  .login-general-links{
    bottom: 20%;
    width: 60%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 40px;
  }

  .login-general-links-img{
    width: 28px;
  }
}

@media screen and (max-width: 450px) {
  .connect-button {
    font-size: 12px;
    padding: 6px;
  }
}

@media screen and (max-width: 450px) {
  .alpha-text-container {
    font-size: 12px;
  }
}

@media screen and (max-width: 370px) {
  .connect-button {
    font-size: 12px;
    padding: 6px;
  }
  .wagmi-title-login {
    margin-right: 30px;
  }


  .login-general-links{
    bottom: 20%;
    width: 70%;
  }
  
  .login-general-links-line{
    height: 4px;
    width: 30px;
  }

  .login-general-links-img{
    width: 25px;
  }
}

@media screen and (max-width: 350px) {
  .connect-button {
    font-size: 12px;
    padding: 6px;
  }
  .wagmi-title-login {
    margin-right: 10px;
  }
  .alpha-text-container {
    font-size: 10px;
  }
}
