* {
  margin: 0px;
}
body {
  height: 100%;
  overflow-x: hidden;
  background: linear-gradient(to right, #3494e6, #ec6ead);
  transition: background 0.3s;
}

@keyframes in {
  0% {
    margin-top: -20vh;
  }
  100% {
  }
}

@keyframes in2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes in3 {
  0% {
    margin-left: -20vw;
  }
  100% {
  }
}
@keyframes in4 {
  0% {
    margin-right: -20vw;
  }
  100% {
  }
}
