#header-dark {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
  }
  
  .dark .header {
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 99;
    border-bottom: 4px solid transparent;
    -o-border-image: linear-gradient(45deg, #3494E6, #EC6EAD);
       border-image: linear-gradient(45deg, #3494E6, #EC6EAD);
    border-image-slice: 1;
    background-color: black;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .wagmi-title{
    cursor: pointer;
  }
  .dark .wagmi-title {
    width: 200px;
    position: absolute;
    left: 80px;
    -webkit-animation: 1s in3;
            animation: 1s in3;
    font-size: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .header-title {
    color: white;
    font-size: 27px;
    font-family: 'Poppins';
    letter-spacing: 3px;
    -webkit-animation: 1s in;
            animation: 1s in;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .connect {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Poppins';
    -webkit-box-ordinal-group: 3;
        
            
    position: absolute;
    right: 80px;
    -webkit-animation: 1s in4;
            animation: 1s in4;
    color: white;
    background-color: black;
    border: 3px solid white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .connect:hover {
    padding: 12px;
    font-size: 17px;
    right: 75px;
  }
  
  .dark .eth-coin-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-ordinal-group: 2;
        
            
    font-family: 'Poppins';
    letter-spacing: 1.1px;
    font-weight: 600;
    position: absolute;
    -webkit-transform: translateX(24vw);
            transform: translateX(24vw);
  }
  
  .dark .eth-img {
    height: 35px;
  }
  
  .dark .eth-things {
    margin-left: 25px;
    color: white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .eth-value-selector {
    border-radius: 5px;
    font-size: 10px;
    font-family: 'Poppins';
  }
  
  .dark .ethereum-result {
    font-size: 11px;
  }
  
  .dark .ethereum {
    font-size: 11px;
  }
  
  .dark .sol-coin-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-ordinal-group: 2;
    font-family: 'Poppins';
    letter-spacing: 1.1px;
    font-weight: 600;
    position: absolute;
    -webkit-transform: translateX(24vw);
            transform: translateX(24vw);
  }
  
  .dark .sol-img {
    height: 35px;
  }
  
  .dark .sol-things {
    margin-left: 25px;
    color: white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .sol-value-selector {
    border-radius: 5px;
    font-size: 10px;
    font-family: 'Poppins';
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .dark .solana-result {
    font-size: 11px;
  }
  
  .dark .solana {
    font-size: 11px;
  }
  

  .bottle-logo{
    display: none;
    width: 55px;
  }

  @media screen and (max-width: 1000px) {
    .dark .eth-img {
      height: 30px;
    }
    .dark .sol-img {
      height: 30px;
    }
    .dark .ethereum-result {
      font-size: 9px;
    }
    .dark .ethereum {
      font-size: 9px;
    }
    .dark .solana-result {
      font-size: 9px;
    }
    .dark .solana {
      font-size: 9px;
    }
    .dark .eth-things {
      margin-left: 15px;
    }
    .dark .wagmi-title {
      width: 170px;
      position: absolute;
      left: 80px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .dark .header-title {
      color: white;
      font-size: 20px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 9px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 15px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 80px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 11px;
      font-size: 16px;
      right: 75px;
    }
  }
  
  @media screen and (max-width: 930px) {
    .dark .eth-img {
      display: none;
    }
    .dark .eth-coin-price {
      -webkit-transform: translateX(22vw);
              transform: translateX(22vw);
    }
    .dark .sol-img {
      display: none;
    }
    .dark .sol-coin-price {
      -webkit-transform: translateX(22vw);
              transform: translateX(22vw);
    }
  }
  
  @media screen and (max-width: 850px) {
    .dark .eth-img {
      height: 20px;
    }
    .dark .eth-things {
      margin-left: 20px;
      color: white;
    }
    .dark .eth-value-selector {
      border-radius: 5px;
      font-size: 8px;
    font-family: 'Poppins';
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .ethereum-result {
      font-size: 9px;
    }
    .dark .ethereum {
      font-size: 9px;
    }
    .dark .sol-img {
      height: 20px;
    }
    .dark .sol-things {
      margin-left: 25px;
      color: white;
    }
    .dark .sol-value-selector {
      border-radius: 5px;
      font-size: 8px;
    font-family: 'Poppins';
    }
    .dark .solana-result {
      font-size: 9px;
    }
    .dark .solana {
      font-size: 9px;
    }
    .dark .wagmi-title {
      width: 150px;
      position: absolute;
      left: 80px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .dark .header-title {
      color: white;
      font-size: 18px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 8px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 80px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 10px;
      font-size: 15px;
      right: 75px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .dark .wagmi-title {
      width: 135px;
      position: absolute;
      left: 65px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .dark .header-title {
      color: white;
      font-size: 17px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 65px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 60px;
    }
  }
  
  @media screen and (max-width: 750px) {
    .dark .sol-coin-price {
      display: none;
    }
    .dark .eth-coin-price {
      display: none;
    }
    .dark .wagmi-title {
      width: 135px;
      position: absolute;
      left: 50px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .dark .header-title {
      color: white;
      font-size: 17px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 690px) {
    .dark .wagmi-title {
      width: 125px;
      position: absolute;
      left: 50px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .dark .header-title {
      color: white;
      font-size: 15px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 6px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 12px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 650px) {
    .dark .wagmi-title {
      display: none;

    }
    .dark .header-title {
      color: white;
      font-size: 14px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 5px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 11px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 40px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .dark .connect:hover {
      padding: 7px;
      font-size: 12px;
      right: 35px;
    }
  }
  
  @media screen and (max-width: 600px) {


    .dark .header-title {
      color: white;
      font-size: 15px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 55px;
    }
  }
  
  @media screen and (max-width: 530px) {
    .dark .header-title {
      color: white;
      font-size: 14px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 13px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .dark .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 12px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 40px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 13px;
      right: 35px;
    }
  }
  
  @media screen and (max-width: 400px) {

    .dark .connect {
      padding: 6px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 11px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 30px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
    }
    .dark .connect:hover {
      padding: 9px;
      font-size: 11px;
      right: 25px;
    }
  }
  
  @media screen and (max-width: 350px) {
    .dark .header {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
    }

    .dark .header-title {
      color: white;
      font-size: 10px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .dark .connect {
      padding: 5px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 10px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 30px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: white;
      background-color: black;
      border: 3px solid white;
    }
    .dark .connect:hover {
      padding: 6px;
      font-size: 11px;
    }
  }
  
  .light .header {
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 99;
    border-bottom: 4px solid transparent;
    -o-border-image: linear-gradient(45deg, #3494E6, #EC6EAD);
       border-image: linear-gradient(45deg, #3494E6, #EC6EAD);
    border-image-slice: 1;
    background-color: white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .wagmi-title {
    width: 200px;
    position: absolute;
    left: 80px;
    -webkit-animation: 1s in3;
            animation: 1s in3;
    font-size: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .header-title {
    color: black;
    font-size: 27px;
    font-family: "Poppins";
    letter-spacing: 3px;
    -webkit-animation: 1s in;
            animation: 1s in;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .connect {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Poppins";
    -webkit-box-ordinal-group: 3;
        
            
    position: absolute;
    right: 80px;
    -webkit-animation: 1s in4;
            animation: 1s in4;
    color: black;
    background-color: white;
    border: 3px solid black;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .connect:hover {
    padding: 12px;
    font-size: 17px;
    right: 75px;
  }
  
  .light .eth-coin-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-ordinal-group: 2;
    font-family: "Poppins";
    letter-spacing: 1.1px;
    font-weight: 600;
    position: absolute;
    -webkit-transform: translateX(24vw);
            transform: translateX(24vw);
  }
  
  .light .eth-img {
    height: 35px;
  }
  
  .light .eth-things {
    margin-left: 25px;
    color: black;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .eth-value-selector {
    border-radius: 5px;
    font-size: 10px;
    font-family: "Poppins";
  }
  
  .light .ethereum-result {
    font-size: 11px;
  }
  
  .light .ethereum {
    font-size: 11px;
  }
  
  .light .sol-coin-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-ordinal-group: 2;
    font-family: "Poppins";
    letter-spacing: 1.1px;
    font-weight: 600;
    position: absolute;
    -webkit-transform: translateX(24vw);
            transform: translateX(24vw);
  }
  
  .light .sol-img {
    height: 35px;
  }
  
  .light .sol-things {
    margin-left: 25px;
    color: black;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .sol-value-selector {
    border-radius: 5px;
    font-size: 10px;
    font-family: "Poppins";
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .light .solana-result {
    font-size: 11px;
  }
  
  .light .solana {
    font-size: 11px;
  }
  
  @media screen and (max-width: 1000px) {
    .light .eth-img {
      height: 30px;
    }
    .light .sol-img {
      height: 30px;
    }
    .light .ethereum-result {
      font-size: 9px;
    }
    .light .ethereum {
      font-size: 9px;
    }
    .light .solana-result {
      font-size: 9px;
    }
    .light .solana {
      font-size: 9px;
    }
    .light .eth-things {
      margin-left: 15px;
    }
    .light .wagmi-title {
      width: 170px;
      position: absolute;
      left: 80px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 20px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 9px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 15px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 80px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 11px;
      font-size: 16px;
      right: 75px;
    }
  }
  
  @media screen and (max-width: 930px) {
    .light .eth-img {
      display: none;
    }
    .light .eth-coin-price {
      -webkit-transform: translateX(22vw);
              transform: translateX(22vw);
    }
    .light .sol-img {
      display: none;
    }
    .light .sol-coin-price {
      -webkit-transform: translateX(22vw);
              transform: translateX(22vw);
    }
  }
  
  @media screen and (max-width: 850px) {
    .light .eth-img {
      height: 20px;
    }
    .light .eth-things {
      margin-left: 20px;
      color: black;
    }
    .light .eth-value-selector {
      border-radius: 5px;
      font-size: 8px;
    font-family: 'Poppins';
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .ethereum-result {
      font-size: 9px;
    }
    .light .ethereum {
      font-size: 9px;
    }
    .light .sol-img {
      height: 20px;
    }
    .light .sol-things {
      margin-left: 25px;
      color: black;
    }
    .light .sol-value-selector {
      border-radius: 5px;
      font-size: 8px;
    font-family: 'Poppins';
    }
    .light .solana-result {
      font-size: 9px;
    }
    .light .solana {
      font-size: 9px;
    }
    .light .wagmi-title {
      width: 150px;
      position: absolute;
      left: 80px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 18px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 8px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 80px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 10px;
      font-size: 15px;
      right: 75px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .light .wagmi-title {
      width: 135px;
      position: absolute;
      left: 65px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 17px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 65px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 60px;
    }
  }
  
  @media screen and (max-width: 750px) {
    .light .sol-coin-price {
      display: none;
    }
    .light .eth-coin-price {
      display: none;
    }
    .light .wagmi-title {
      width: 135px;
      position: absolute;
      left: 50px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 17px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 690px) {
    .light .wagmi-title {
      width: 125px;
      position: absolute;
      left: 50px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 15px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 6px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 12px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 650px) {
    .light .wagmi-title {
      display: none;
    }
    .bottle-logo{
      display: inline;
    }
    .light .wagmi-title {
      width: 120px;
      position: absolute;
      left: 40px;
      -webkit-animation: 1s in3;
              animation: 1s in3;
      font-size: 20px;
    }
    .light .header-title {
      color: black;
      font-size: 14px;
    font-family: 'Poppins';
      letter-spacing: 3px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 5px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 11px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 40px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .light .connect:hover {
      padding: 7px;
      font-size: 12px;
      right: 35px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .light .header {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
    }

    .light .header-title {
      color: black;
      font-size: 15px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 60px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 14px;
      right: 55px;
    }
  }
  
  @media screen and (max-width: 530px) {
    .light .header-title {
      color: black;
      font-size: 14px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 13px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 50px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 13px;
      right: 45px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .bottle-logo{
      width: 50px;
    }
    .light .connect {
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 12px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 40px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 13px;
      right: 35px;
    }
  }
  
  @media screen and (max-width: 400px) {

    .light .connect {
      padding: 6px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 11px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 30px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
    }
    .light .connect:hover {
      padding: 9px;
      font-size: 11px;
      right: 25px;
    }
  }
  
  @media screen and (max-width: 350px) {
    .light .header {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
    }
    .light .wagmi-title {
      display: none;
    }
    .light .header-title {
      color: black;
      font-size: 10px;
    font-family: 'Poppins';
      letter-spacing: 1.5px;
      -webkit-animation: 1s in;
              animation: 1s in;
    }
    .light .connect {
      padding: 5px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 10px;
    font-family: 'Poppins';
      -webkit-box-ordinal-group: 3;
          
              
      position: absolute;
      right: 30px;
      -webkit-animation: 1s in4;
              animation: 1s in4;
      color: black;
      background-color: white;
      border: 3px solid black;
    }
    .light .connect:hover {
      padding: 6px;
      font-size: 11px;
    }
  }