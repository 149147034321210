#page-wrapper.modal-layout {
  background-color: var(--dark-background-color);
}

#page-wrapper.modal-layout #header-dark,
#page-wrapper.modal-layout .div-marketplace-blockchain .full-page-option,
#page-wrapper.modal-layout .div-marketplace-blockchain .mode-image {
  display: none;
}

#page-wrapper.modal-layout .div-marketplace-blockchain {
  padding: 1.5rem 0;
}

#page-wrapper.modal-layout .txs-container {
  padding-bottom: 0;
}

#page-wrapper.modal-layout .input-label-container {
  margin: 1.5rem 0px 1rem 0px;
}

#page-wrapper.modal-layout .result-name,
#page-wrapper.modal-layout .text-result {
  margin-bottom: 1.5rem;
}

#page-wrapper.modal-layout {
  min-height: 100vh;
}
#page-wrapper.modal-layout > div {
  min-height: 100vh;
}

#page-wrapper.modal-layout .align-calculator {
  margin-left: 0 !important;
  height: auto;
}

#page-wrapper.modal-layout .tool-selector-container,
#page-wrapper.modal-layout .div-marketplace-blockchain,
#page-wrapper.modal-layout .txs-container {
  position: initial;
}

#page-wrapper.modal-layout .checking-text {
  min-height: 100vh;
  height: 100vh;
}

#page-wrapper.modal-layout .tool-selector-container,
#page-wrapper.modal-layout .tool-selector-container2 {
  width: 100%;
  height: 60px;
  font-family: "Poppins";
}

#page-wrapper.modal-layout #result1,
#page-wrapper.modal-layout #result2,
#page-wrapper.modal-layout #result3,
#page-wrapper.modal-layout #result4 {
  padding-bottom: 30px;
}

#page-wrapper.modal-layout .tool-selector-container2 {
  flex-direction: row;
}

#page-wrapper.modal-layout .dark .tool-selector-container2 {
  color: white;
}

#page-wrapper.modal-layout .tool-selector-container2 > b {
  min-width: 150px;
  padding: 0.3rem;
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid black;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
}

#page-wrapper.modal-layout .tool-selector-container2 > b.active {
  border: 2px solid transparent;
  background-color: black;
  font-weight: bold;
}

#page-wrapper.modal-layout .tool-selector-container3 {
  display: none;
}

#page-wrapper.modal-layout .tool-selector-image.active {
  transform: translateY(20px);
}

#page-wrapper.modal-layout h1 {
  display: none;
}

#page-wrapper.modal-layout .div-marketplace-blockchain .eth-things {
  display: flex;
  margin-left: 4px;
}

#page-wrapper.modal-layout .div-marketplace-blockchain .eth-coin-price img,
#page-wrapper.modal-layout .div-marketplace-blockchain .eth-things .ethereum-result,
#page-wrapper.modal-layout .div-marketplace-blockchain .eth-things .ethereum,
.select-currency-text {
  display: none;
}

#page-wrapper.modal-layout .select-currency-text {
  display: block;
  font-weight: 400;
  letter-spacing: initial;
}

#page-wrapper.modal-layout .div-marketplace-blockchain .eth-value-selector {
  margin-left: 1rem;
}

#page-wrapper.modal-layout .div-marketplace-blockchain .eth-coin-price {
  position: initial;
  transform: none;
  margin-left: 1rem;
}

#page-wrapper.modal-layout .div-marketplace-blockchain {
  align-items: center;
}
