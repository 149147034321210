:root {
  --dark-background-color: #252527;
}

body:has(.dark)::-webkit-scrollbar {
  width: 10px;
  background-color: #252527;
}

body:has(.light)::-webkit-scrollbar {
  width: 10px;
  background-color: #ffffff;
}

body:has(.dark)::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

body:has(.light)::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.light {
  background-color: white;
  transition: background-color 300ms;
}
html:has(.light) {
  background-color: white;
  transition: background-color 300ms;
}
.dark {
  transition: background-color 300ms;
}
body {
  background-color: var(--dark-background-color) !important;
}
.img {
  width: 50px;
}
.div {
  color: white;
}
.div-marketplace-blockchain {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: -webkit-sticky;
  position: sticky;
  top: 84px;
  padding: 25px 0px 25px 0px;
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Poppins";
  z-index: 99;

  padding-left: 10%;
  padding-right: 10%;
}
.dark .div-marketplace-blockchain {
  background-color: var(--dark-background-color);
  color: white;
}

.dark .text-result {
  margin-bottom: 30px;
  padding: 0 25px;
}

.dark #result3 {
  margin-bottom: 30px;
  padding: 0 25px;
}

.dark #result4 {
  margin-bottom: 30px;
  padding: 0 25px;
}

.dark #blockchain,
.dark #marketplace,
#page-wrapper.modal-layout .dark .eth-value-selector {
  background-color: #323232;
  color: white;
  border: 2px solid white;
  font-family: "Poppins";
  border-radius: 8px;
  margin-left: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 13px;
}

.dark .mode-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.dark .mode-image p {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.dark input {
  font-family: "Poppins";
}

.dark #sun-moon-img {
  margin-right: 10px;
  width: 20px;
  right: 28vw;
  content: url(../../images/moon.png);
  cursor: pointer;
}

.dark .input-label-container {
  text-align: center;
  margin: 35px 0px 20px 0px;
  font-family: "Poppins";
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  -webkit-animation: inCalculator 1.5s;
  animation: inCalculator 1.5s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.calc-title {
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 4px;
}
#button1,
#button2,
#button3,
#button4 {
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 1px;
}
#result1,
#result2,
#result3,
#result4 {
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 0.8px;
}
.dark .calc-title {
  font-size: 30px;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 60px;
  color: white;
  background-color: var(--dark-background-color);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .input-label-f1 {
  position: relative;
  display: inline;
}

.input-label-f1,
.input-label-f1-hide,
.input-label-f1-hide2,
.input-label-f2,
.input-label-f2-hide,
.input-label-f2-hide2,
.input-label-f3,
.input-label-f3-hide,
.input-label-f3-hide2 {
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 1.6px;
}

.dark .input-label-f2 {
  position: relative;
  display: inline;
}

.dark .input-label-f3 {
  position: relative;
  display: inline;
}

.dark .input-label-f4 {
  position: relative;
  display: inline;
}

.dark .input-label-f1-hide {
  position: relative;
  display: inline;
}

.dark .input-label-f2-hide {
  position: relative;
  display: inline;
}

.dark .input-label-f3-hide {
  position: relative;
  display: inline;
}

.dark .input-label-f1-hide2 {
  position: relative;
  display: inline;
}

.dark .input-label-f2-hide2 {
  position: relative;
  display: inline;
}

.dark .input-label-f3-hide2 {
  position: relative;
  display: inline;
}

.dark .input-f1 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .input-f2 {
  height: 30px;
  width: 180px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .input-f3 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .input-f4 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .label-f1 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: white;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .label-f2 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: white;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .label-f3 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: white;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.calc-title1 {
  margin-top: 30px !important;
}

.dark .label-f4 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: white;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .input-f1:focus ~ .label-f1,
.dark .input-f1:not(:placeholder-shown).input-f1:not(:focus) ~ .label-f1 {
  top: -15px;
  font-size: 9px;
  background-color: var(--dark-background-color);
  padding: 0px 3px;
}

.dark .input-f2:focus ~ .label-f2,
.dark .input-f2:not(:placeholder-shown).input-f2:not(:focus) ~ .label-f2 {
  top: -15px;
  font-size: 9px;
  background-color: var(--dark-background-color);
  padding: 0px 3px;
}

.dark .input-f3:focus ~ .label-f3,
.dark .input-f3:not(:placeholder-shown).input-f3:not(:focus) ~ .label-f3 {
  top: -15px;
  font-size: 9px;
  background-color: var(--dark-background-color);
  padding: 0px 3px;
}

.dark .input-f4:focus ~ .label-f4,
.dark .input-f4:not(:placeholder-shown).input-f4:not(:focus) ~ .label-f4 {
  top: -15px;
  font-size: 9px;
  background-color: var(--dark-background-color);
  padding: 0px 3px;
}

.dark .input-f1-bright:focus ~ .label-f1-bright,
.dark .input-f1-bright:not(:placeholder-shown).input-f1-bright:not(:focus) ~ .label-f1-bright {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.dark .input-f2-bright:focus ~ .label-f2-bright,
.dark .input-f2-bright:not(:placeholder-shown).input-f2-bright:not(:focus) ~ .label-f2-bright {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.dark .input-f3-bright:focus ~ .label-f3-bright,
.dark .input-f3-bright:not(:placeholder-shown).input-f3-bright:not(:focus) ~ .label-f3-bright {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.dark .input-f4-bright:focus ~ .label-f4-bright,
.dark .input-f4-bright:not(:placeholder-shown).input-f4-bright:not(:focus) ~ .label-f4-bright {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.dark .in {
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  margin-left: -5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark #profittype {
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  background-color: #323232;
  color: white;
  border: 3px solid white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark input[type="number"]::-webkit-inner-spin-button,
.dark input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dark #result1 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  -webkit-transition: all 0.3s, padding-bottom 0s;
  transition: all 0.3s, padding-bottom 0s;
}

.dark #result2 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  -webkit-transition: all 0.3s, padding-bottom 0s;
  transition: all 0.3s, padding-bottom 0s;
}

.dark #result3 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  -webkit-transition: all 0.3s, padding-bottom 0s;
  transition: all 0.3s, padding-bottom 0s;
}

.dark #result4 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 50px;
  color: white;
  -webkit-transition: all 0.3s, padding-bottom 0s;
  transition: all 0.3s, padding-bottom 0s;
}

.dark .result-name {
  text-align: center;
  font-family: "Poppins";
  color: white;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .button-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dark #button1 {
  width: 120px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #323232;
  border: 3px solid white;
}

.dark #button2 {
  width: 120px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #323232;
  border: 3px solid white;
}

.dark #button3 {
  width: 120px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #323232;
  border: 3px solid white;
}

.dark #button4 {
  width: 120px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #323232;
  border: 3px solid white;
}

.dark #copyfield {
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .copyvalue {
  border: 3px solid white;
  color: white;
  background-color: rgb 0, 0, 0;
  margin-left: 20px;
  border-radius: 7px;
  padding: 3px 7px;
  padding-right: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .copyvalue-2 {
  border: 3px solid white;
  color: white;
  background-color: rgb 0, 0, 0;
  margin-left: 20px;
  border-radius: 7px;
  padding: 3px 7px;
  padding-right: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .copy-btn {
  background-color: white;
  color: black;
  border: 2px solid white;
  padding: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .copy-btn-2 {
  background-color: white;
  color: black;
  border: 2px solid white;
  padding: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .profit-number {
  color: lime;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .loss-number {
  color: red;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .eth-number {
  color: #627eea;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .sol-number {
  color: #ac50eb;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .div-marketplace-blockchain {
  background-color: #ffffff;
  color: black;
}

.full-page-option,
.mode-image,
.market {
  margin-left: 20px;
}

.light .text-result {
  margin-bottom: 30px;
  padding: 0 25px;
}

.light #result3 {
  margin-bottom: 30px;
  padding: 0 25px;
}

.light #result4 {
  margin-bottom: 30px;
  padding: 0 25px;
}

.light #blockchain {
  background-color: #e5e5e5;
  color: black;
  border: 2px solid black;
  font-family: "Poppins";
  border-radius: 8px;
  margin-left: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #marketplace {
  background-color: #e5e5e5;
  color: black;
  border: 2px solid black;
  font-family: "Poppins";
  border-radius: 8px;
  margin-left: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .mode-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.light .mode-image p {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.light input {
  font-family: "Poppins";
}

.light #sun-moon-img {
  margin-right: 10px;
  width: 20px;
  right: 28vw;
  content: url(../../images/sun.png);
  cursor: pointer;
}

.light .input-label-container {
  text-align: center;
  margin: 35px 0px 20px 0px;
  font-family: "Poppins";
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  -webkit-animation: inCalculator 1.5s;
  animation: inCalculator 1.5s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .calc-title {
  font-size: 30px;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 60px;
  color: #060b23;
  background-color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .input-label-f1 {
  position: relative;
  display: inline;
}

.light .input-label-f2 {
  position: relative;
  display: inline;
}

.light .input-label-f3 {
  position: relative;
  display: inline;
}

.light .input-label-f4 {
  position: relative;
  display: inline;
}

.light .input-label-f1-hide {
  position: relative;
  display: inline;
}

.light .input-label-f2-hide {
  position: relative;
  display: inline;
}

.light .input-label-f3-hide {
  position: relative;
  display: inline;
}

.light .input-label-f1-hide2 {
  position: relative;
  display: inline;
}

.light .input-label-f2-hide2 {
  position: relative;
  display: inline;
}

.light .input-label-f3-hide2 {
  position: relative;
  display: inline;
}

.light .input-f1 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  border: 2px solid #000000;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .input-f2 {
  height: 30px;
  width: 180px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  border: 2px solid #000000;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .input-f3 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  border: 2px solid #000000;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .input-f4 {
  height: 30px;
  width: 200px;
  margin: 10px 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  border: 2px solid #000000;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .label-f1 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: black;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .label-f2 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: black;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .label-f3 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: black;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .label-f4 {
  position: absolute;
  left: 20px;
  top: 0px;
  cursor: text;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  color: black;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .input-f1:focus ~ .label-f1,
.light .input-f1:not(:placeholder-shown).input-f1:not(:focus) ~ .label-f1 {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.light .input-f2:focus ~ .label-f2,
.light .input-f2:not(:placeholder-shown).input-f2:not(:focus) ~ .label-f2 {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.light .input-f3:focus ~ .label-f3,
.light .input-f3:not(:placeholder-shown).input-f3:not(:focus) ~ .label-f3 {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.light .input-f4:focus ~ .label-f4,
.light .input-f4:not(:placeholder-shown).input-f4:not(:focus) ~ .label-f4 {
  top: -15px;
  font-size: 9px;
  background-color: #ffffff;
  padding: 0px 3px;
}

.light .in {
  color: black;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  margin-left: -5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #profittype {
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  color: black;
  border: 2px solid black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light input[type="number"]::-webkit-inner-spin-button,
.light input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.light #result1 {
  text-align: center;
  text-transform: uppercase;
  color: black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #result2 {
  text-align: center;
  text-transform: uppercase;
  color: black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #result3 {
  text-align: center;
  text-transform: uppercase;
  color: black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #result4 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 50px;
  color: black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .result-name {
  text-align: center;
  font-family: "Poppins";
  color: black;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .button-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.light #button1 {
  width: 120px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: black;
  background-color: #e5e5e5;
  border: 3px solid black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #button2 {
  width: 120px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: black;
  background-color: #e5e5e5;
  border: 3px solid black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #button3 {
  width: 120px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: black;
  background-color: #e5e5e5;
  border: 3px solid black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #button4 {
  width: 120px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: black;
  background-color: #e5e5e5;
  border: 3px solid black;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light #copyfield {
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.light .copyvalue {
  border: 3px solid black;
  color: black;
  background-color: white;
  margin-left: 20px;
  border-radius: 7px;
  padding: 3px 7px;
  padding-right: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .copyvalue-2 {
  border: 3px solid black;
  color: black;
  background-color: white;
  margin-left: 20px;
  border-radius: 7px;
  padding: 3px 7px;
  padding-right: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .copy-btn {
  background-color: black;
  color: white;
  border: 2px solid black;
  padding: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .copy-btn-2 {
  background-color: black;
  color: white;
  border: 2px solid black;
  padding: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .profit-number {
  color: #0ec40e;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .loss-number {
  color: #da0000;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .eth-number {
  color: #3455dd;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.light .sol-number {
  color: #a124f5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/*            */

.txs-container {
  flex-wrap: wrap;
  padding-left: 12%;
  padding-right: 12%;
  margin: 0 auto;
  position: sticky;
  top: calc(77px + 86px);
  font-family: "Poppins";
  transition: all 0.3s;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding-bottom: 25px;
  display: flex;
}

.dark .txs-container {
  color: white;
  background-color: var(--dark-background-color);
}
.light .txs-container {
  color: black;
  background-color: white;
}

.txs-nft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.txs-select {
  padding: 5px 0 5px 15px;
  position: relative;
  cursor: pointer;
  width: 215px;
  margin-left: 20px;
  border-radius: 7px;
}

.dark .txs-select {
  background-color: transparent;
  border: 2px solid white;
}

.light .txs-select {
  background-color: #e5e5e5;
  border: 2px solid black;
}

.dark .dropdown-img,
.dropdown-img-2 {
  position: absolute;
  right: 10px;
  width: 30px;
  filter: invert(100);
  transition: all 500ms;
}

.light .dropdown-img,
.light .dropdown-img-2 {
  position: absolute;
  right: 10px;
  width: 30px;
  filter: invert(100);
  transition: all 500ms;
  filter: invert(0);
}

.dropdown-img.rotate,
.dropdown-img-2.rotate {
  transform: rotate(180deg);
}

.dark .transactions-container,
.dark .calculators-container {
  padding: 0 8px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  position: absolute;
  left: 0px;
  top: 55px;
  border: 2px solid white;
  width: 212px;
  border-radius: 7px;
  background-color: var(--dark-background-color);
  color: white;
  z-index: 99;
  overflow-y: scroll;
  max-height: 250px;
}

.light .transactions-container,
.light .calculators-container {
  padding: 0 8px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  position: absolute;
  left: 0;
  top: 55px;
  border: 2px solid black;
  width: 212px;
  border-radius: 7px;
  background-color: #e5e5e5;
  color: black;
  z-index: 99;
  overflow-y: scroll;
  max-height: 250px;
}

.calculators-container::-webkit-scrollbar {
  display: none;
}

.single-nft-tx {
  display: flex;
  margin: 20px 0;
}
.single-nft-tx div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.single-nft-tx img {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  margin-right: 10px;
}

.dark .questionImage {
  filter: invert(1);
  z-index: -1;
}

.light .questionImage {
  filter: invert(0);
  z-index: -1;
}

/*
.dark .single-transaction{
  position: sticky;
  top: 0;
  background-color: white;
}
.light .single-transaction{
  position: sticky;
  top: 0;
  background-color: black;
}
*/

/* .transactions-container{
  padding-top: 0 !important;
} */

.single-transaction {
  padding: 7px 0;
  display: flex;
  align-items: center;
}

.sticky-transaction {
  position: sticky;
  top: 0;
}

.nft-search-bar-container {
  background-color: var(--dark-background-color);
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.light .nft-search-bar-container {
  background-color: #e5e5e5;
}
.nft-search-bar {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 5px;
}

.dark .nft-search-bar {
  color: white;
  border: 1px solid white;
}
.light .nft-search-bar {
  color: black;
  border: 1px solid black;
}

.no-tx-found {
  padding: 8px 0;
}
.dark .single-transaction {
  background-color: var(--dark-background-color);
}
.light .single-transaction {
  background-color: #e5e5e5;
}
.single-transaction input {
  margin-left: 10px;
}

.transactions-container::-webkit-scrollbar {
  display: none;
}

.calculators-nft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calculators-select {
  width: 200px;
  margin-left: 20px;
  padding: 5px 0 5px 30px;
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  border-radius: 7px;
}

.dark .calculators-select {
  background-color: transparent;
  border: 2px solid white;
}

.light .calculators-select {
  background-color: #e5e5e5;
  border: 2px solid black;
}

.calculators-select-text-image {
  display: flex;
  align-items: center;
}

.calculators-select-text-img {
  height: 30px;
}

.select-text-img {
  height: 30px;
  display: flex;
  align-items: center;
}

.single-calculator-select {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 2px 0;
}
.single-calculator-select *:first-child {
  order: 2;
}

.single-calculator-select *:last-child {
  order: 1;
}

.single-calculator-select input:checked {
  background: greenyellow;
}
.single-calculator-select p {
  margin-left: 10px;
}

.nft-image-select {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 5px;
}

.nft-name-select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.nft-name {
  display: flex;
  align-items: center;
  max-width: 160px;
}

.dark .clear-btn {
  width: 120px;
  transition: all 0.3s;
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #9d0f0f;
  border: 2px solid white;
  margin-left: 30px;
}

.light .clear-btn {
  width: 120px;
  transition: all 0.3s;
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 15px;
  cursor: pointer;
  color: black;
  background-color: #fb2727;
  border: 2px solid black;
  margin-left: 30px;
}

.clear-all-btn {
  position: absolute;
  left: 0;

  width: 100px;
  transition: all 0.3s;
  font-family: "Poppins";
  letter-spacing: 1.5px;
  border-radius: 10px;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  background-color: #be0b0b;
  border: 1px solid white;
  margin-left: 40px;
}

.developing-img {
  width: 25px;
  margin-right: 15px;
}

.dark .developing-img {
  filter: invert(1);
}

.connect-phantom-button {
  letter-spacing: 1.1px;
  font-family: inherit;
  height: 100%;
  margin: auto 0;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dark .connect-phantom-button {
  background-color: transparent;
  color: white;
}

.checking-text {
  display: table-cell;
  text-align: center;
  width: 100vw;
  height: calc(100vh - 84px);
  vertical-align: middle;
}

.loading-trades {
  padding: 5px 0;
  margin-left: 20px;
}

.help-icon {
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
  filter: invert(1);
  margin-left: 15px;
}

.tools-instructions-btn {
  position: absolute;
  right: 30px;
  font-size: 20px;
  font-family: "Fredoka", sans-serif;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  cursor: pointer;
}

.tools-instructions-btn div {
  margin-left: 10px;
}

.dark .tools-instructions-btn {
  color: white;
}

.light .tools-instructions-btn {
  color: black;
}

.tools-instructions-img {
  height: 18px;
}

.dark .tools-instructions-img {
  filter: invert(1);
}

.align-calculator {
  height: calc(100vh - 234px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-calculator #result1,
.align-calculator #result2,
.align-calculator #result3,
.align-calculator #result4 {
  padding-bottom: 50px;
}

.tool-selector-container2 {
  color: black;
  top: 0px;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 100%) 0px 0px 40px 0px;
  /* background-color: white; */
}

.light .tool-selector-container2 {
  box-shadow: rgb(87 87 87 / 20%) 0px 0px 40px 0px;
}

.tool-selector-container {
  color: black;
  top: 0px;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 80px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: var(--dark-background-color);
}

.light .tool-selector-container {
  background-color: rgb(255, 255, 255);
}

.tool-selector-container3 {
  height: 100%;
  width: 80px;
  position: relative;
  overflow: hidden;
}

.tool-selector-image {
  cursor: pointer;
  width: 30px;
  /* background-color: #8a74aa; */
  /* border-radius: 50%; */

  transition: all 500ms;
}

.dark .tool-selector-image {
  filter: invert(1);
}

.tool-selector-image.active {
  transform: translateX(20px);
}

.indicator {
  position: absolute;
  top: 55px;
  right: -13px;
  width: 70px;
  height: 70px;
  background-color: #141415;
  border-radius: 50%;
  z-index: -1;

  transition: all 500ms ease 0s;
}

.indicator::before {
  content: " ";
  position: absolute;
  top: -40px;
  border-bottom-right-radius: 50%;
  box-shadow: 20px 15px 0 0px #141415;
  right: 9px;
  width: 40px;
  height: 40px;
}

.indicator::after {
  content: " ";
  position: absolute;
  bottom: -40px;
  border-top-right-radius: 50%;
  box-shadow: 20px -15px 0 0px #141415;
  right: 9px;
  width: 40px;
  height: 40px;
}

.light .indicator {
  background-color: #eeeeee;
}

.light .indicator::after {
  box-shadow: 20px -15px 0 0px #eeeeee;
}

.light .indicator::before {
  box-shadow: 20px 15px 0 0px #eeeeee;
}

.full-page-img-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.full-page-img {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;

  transition: filter 300ms;
}

.light .full-page-img {
  filter: invert(1);
}

.align-calculator.hidden-overflow {
  overflow: hidden;
}

.hamburger {
  display: none;
}

.hamburger .line {
  border-radius: 50px;
  width: 30px;
  height: 3px;
  background-color: #ecf0f1;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.light .hamburger .line {
  background-color: black;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

/* @media screen and (max-width: 600px) {
  .dark .div-marketplace-blockchain {
    font-size: 11px;
  }
} */

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
}

.load {
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-bottom: 40px;
}

.load .line:nth-last-child(1) {
  animation: loading 0.6s 0.1s linear infinite;
}
.load .line:nth-last-child(2) {
  animation: loading 0.6s 0.2s linear infinite;
}
.load .line:nth-last-child(3) {
  animation: loading 0.6s 0.3s linear infinite;
}

@keyframes loading {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 520px) {
  /* .dark .mode-image {
    position: absolute;
    -webkit-transform: translate(-35px, 50px);
    transform: translate(-35px, 50px);
  }
  .dark .div-marketplace-blockchain {
    padding-bottom: 80px;
  } */
  .dark .calc-title1 {
    margin-top: -25px;
  }
}

@media screen and (max-width: 420px) {
  .dark .calc-title {
    font-size: 25px;
  }
}

@media screen and (max-width: 360px) {
  /* .dark #blockchain {
    font-size: 10px;
  }
  .dark #marketplace {
    font-size: 10px;
  }
  .dark .div-marketplace-blockchain div:nth-child(2) {
    margin: 0px;
  } */
  .dark .calc-title {
    font-size: 20px;
  }
}

/* @media screen and (max-width: 600px) {
  .light .div-marketplace-blockchain {
    font-size: 11px;
  }
} */

@media screen and (max-width: 520px) {
  /* .light .mode-image {
    position: absolute;
    -webkit-transform: translate(-35px, 50px);
    transform: translate(-35px, 50px);
  } */
  /* .light .div-marketplace-blockchain {
    padding-bottom: 80px;
  } */
  .light .calc-title1 {
    margin-top: -25px;
  }
}

@media screen and (max-width: 420px) {
  .light .calc-title {
    font-size: 25px;
  }
}

@media screen and (max-width: 360px) {
  /* .light #blockchain {
    font-size: 10px;
  }
  .light #marketplace {
    font-size: 10px;
  }
  .light .div-marketplace-blockchain div:nth-child(2) {
    margin: 0px;
  } */
  .light .calc-title {
    font-size: 20px;
  }
}

.burger-menu-container {
  position: absolute;
  top: 0;
  background-color: #252527;
  height: calc(100vh - 84px);
  width: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.light .burger-menu-container {
  background-color: white;
}

.burger-tool-select {
  cursor: pointer;
  color: white;
  font-family: "Poppins";
  font-size: 30px;
  padding: 25px 50px;
  border-radius: 8px;

  transition: all 300ms;
}

.light .burger-tool-select {
  color: black;
}

.burger-tool-select.is-active,
.burger-tool-select:hover {
  box-shadow: rgb(0 0 0) 4px 4px 13px 0px;
  background-color: rgb(55, 55, 55);
}

.light .burger-tool-select.is-active,
.light .burger-tool-select:hover {
  box-shadow: rgb(87 87 87 / 20%) 4px 4px 13px 0px;
  background-color: rgb(222, 222, 222);
}

@media screen and (max-width: 880px) {
  .calculators-select {
    margin-right: 0;
  }
  .txs-container {
    padding-left: calc(12% + 15px);
    padding-right: calc(12% + 15px);
    gap: 15px;
  }
  .txs-select,
  .calculators-select {
    padding: 0 0 0 15px;
  }
  .nft-image-select {
    width: 20px;
    height: 20px;
  }
  .align-calculator {
    height: calc(100vh - 224px);
  }
  .div-marketplace-blockchain {
    padding-left: 20%;
    padding-right: 20%;
    flex-wrap: wrap;
  }
  .div-marketplace-blockchain > div:nth-child(2) {
    margin-top: 20px;
  }

  .txs-container {
    top: calc(117px + 84px);
  }

  .align-calculator {
    height: calc(100vh - 274px);
  }
}

@media screen and (max-width: 800px) {
  .full-page-option {
    margin-left: 0px;
  }
}

@media screen and (max-width: 787px) {
  .txs-container {
    top: calc(117px + 94px);
  }

  .align-calculator {
    height: calc(100vh - 280px);
  }
}

@media screen and (max-width: 700px) {
  .div-marketplace-blockchain {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 650px) {
  .tool-selector-container {
    display: none;
  }
  .align-calculator {
    margin-left: 0 !important;
    height: unset;
  }
  .connect {
    position: static !important;
  }
  .header {
    justify-content: space-around !important;
  }
  .hamburger {
    display: inline;
  }
}

@media screen and (max-width: 500px) {
  .div-marketplace-blockchain {
    padding-left: 0;
    padding-right: 0;
  }
  .transactions-container,
  .calculators-container {
    top: 45px !important;
  }
}

@media screen and (max-width: 430px) {
  .block,
  .market {
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .burger-tool-select {
    font-size: 28px;
  }
}

@media screen and (max-width: 361px) {
  .burger-tool-select {
    font-size: 26px;
  }
}

@media screen and (max-width: 342px) {
  .burger-tool-select {
    font-size: 23px;
  }
}

@media screen and (max-width: 350px) {
  .block select,
  .market select {
    max-width: 80px;
  }
  .calculators-select {
    max-width: 150px;
  }
  .txs-select {
    max-width: 170px;
  }
  .transactions-container {
    width: 170px !important;
  }
  .calculators-container {
    width: 150px !important;
  }
}
